import { useState, useEffect } from 'react';
import * as React from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    Grid,
    Typography,
    Stack,
    useMediaQuery,
    Button,
    ButtonGroup,
} from '@mui/material';
// assets
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useTheme, alpha } from '@mui/material/styles';
import { useSelector, dispatch } from 'store';
import { setPageSelected } from 'store/slices/menu';
// import CartDiscount from './CartDiscount';
import OrderSummary from './OrderSummary';
import SelectPaymentOption from './SelectPaymentOption';
import CartSummaryTable from './summaryTable';
import CheckoutButton from './buttons/checkout';
import SummaryPaymentScreen from 'components/stripe/displayPaymentMethods';
import checkoutGeneral from 'actions/checkout/checkout';
import CollectCC from 'components/stripe/CollectCardInfo';
import DisplayCardOptions from 'components/account/cc-display/displayCardsCheckout';
import ProcessingCC from 'components/stripe/new/processingCCStatus';
import setSuccess from 'actions/status/setAlert';
import { logout, auth } from 'firebaseConfig';
import NotificationAlert from 'ui-component/notifications/generalAlert';

import {
    updateQuantityInCart,
    deleteEventItemCompletelyFromCart,
    clearCart,
    setCartJustCheckedOut,
    clearPurchasingPackage,
    setIsProcessing,
    setIsProcessingCreditCardNow,
    setShowAllExistingPaymentOptions,
    setShowCheckoutButton,
    setShowSummaryPaymentScreen,
    setShowChooseSingleOrPackage,
} from 'store/slices/cart';

import {
    setHasErrorReducer,
    setErrorMessageReducer,
    setSecondsToShow,
    clearErrorMessageReducer,
    setHasSuccessReducer,
    setSuccessMessageReducer,
    clearSuccessMessageReducer,
} from 'store/slices/error';
import sendConfirmationEmail from 'actions/emails/send-confirmation-class';

const updateItemQuantity = (id, quantity) => {
    const tosend = {
        eventid: id,
        updatedQuantity: quantity,
    };
    dispatch(updateQuantityInCart(tosend));
};

// const removeItem = (id) => {
//     dispatch(deleteEventItemCompletelyFromCart(id));
// };

const Increment = ({ itemId, quantity }) => {
    const [value, setValue] = useState(quantity);

    const incrementHandler = () => {
        setValue(value - 1);
        updateItemQuantity(itemId, value - 1);
    };

    const decrementHandler = () => {
        setValue(value + 1);
        updateItemQuantity(itemId, value + 1);
    };

    return (
        <ButtonGroup
            size={window.isTiny ? 'small' : 'large'}
            variant="text"
            color="inherit"
            sx={{
                border: '1px solid',
                borderColor: 'grey.400',
            }}>
            <Button
                key="three"
                disabled={value <= 1}
                onClick={incrementHandler}
                sx={{
                    pr: 0.75,
                    pl: 0.75,
                    minWidth: '0px !important',
                    '&:enabled': {
                        color: '#495057',
                    },
                }}>
                <RemoveIcon fontSize="inherit" />
            </Button>
            <Button
                key="two"
                sx={{
                    pl: 0.5,
                    pr: 0.5,
                    color: '#495057',
                    fontWeight: 400,
                    fontSize: window.isTiny ? '0.6rem' : '0.9rem',
                    minWidth: '30px !important',
                }}>
                {value}
            </Button>
            <Button
                key="one"
                onClick={decrementHandler}
                disabled={value >= 4}
                sx={{
                    pl: 0.75,
                    pr: 0.75,
                    minWidth: '0px !important',
                    color: '#495057',
                }}>
                <AddIcon fontSize="inherit" />
            </Button>
        </ButtonGroup>
    );
};

Increment.propTypes = {
    itemId: PropTypes.number,
    quantity: PropTypes.number,
    updateQuantity: PropTypes.func,
};

const getWidthForTable = () => {
    return parseInt(window.innerWidth * 0.8);
};

// ==============================|| CART - CHECKOUT LIST ||============================== //

const CheckoutList = ({ dibsId, payments }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();
    const intentType = window.localStorage.getItem('intentType');

    const {
        cartPreparedForCheckout,
        totalAfterCreditApplied,
        creditApplied,
        total,
        showChooseSingleOrPackage,
        showSummaryPaymentScreen,
        isProcessingCreditCardNow,
        showCCCapture,
        cardtocharge,
        showAllExistingPaymentOptions,
        showCheckoutButton,
        isUsingCreditToCoverClass,
    } = useSelector((state) => state.cart);
    const { userid, stripeidForThisStudio, paymentOptionsAll, stripeid } =
        useSelector((state) => state.thisuser);
    const { color } = useSelector((state) => state.dibsstudio.studioConfig);
    const colortouse = `#${color}`;
    const bglighter = alpha(colortouse, 0.3);
    const [rows, setRows] = useState(cartPreparedForCheckout);
    const [widthOfTable, setWidthOfTable] = useState(650);
    const [totaltopay, setTotaltopay] = useState(totalAfterCreditApplied);

    const [hasClasses, setHasClasses] = useState(false);
    const [chosePayPerClass, setChosePayPerClass] = useState(false);
    const [showConfirmButton, setShowConfirmButton] = useState(false);
    const [isBuyingSomething, setIsBuyingSomething] = useState(false);
    const [showCreditCardForm, setShowCreditCardForm] = useState(false);
    const [chosenSingleOrPackage, setChosenSingleOrPackage] = useState(false);
    const [dialogIsOpen, setDialogIsOpen] = useState(false);
    useEffect(() => {
        if (rows.length === 0) {
            dispatch(setShowCheckoutButton(false));
        }
    }, [rows]);

    useEffect(() => {
        dispatch(setIsProcessing(false));
        let itemNotCoveredByPackageExists = false;
        cartPreparedForCheckout.forEach((item) => {
            const { howtopay } = item;
            const { passid, amountToCharge } = howtopay;
            if (passid === 0 && amountToCharge.total > 0) {
                itemNotCoveredByPackageExists = true;
            }
        });
        if (itemNotCoveredByPackageExists) {
            // there is an event that is not covered by a package
            setShowConfirmButton(false);
            dispatch(setShowChooseSingleOrPackage(true));
            dispatch(setShowCheckoutButton(false));
        } else {
            // all events are covered by a package OR are free
            setShowConfirmButton(true);
            dispatch(setShowChooseSingleOrPackage(false));
            dispatch(setShowCheckoutButton(true));
        }
    }, [cartPreparedForCheckout]);

    useEffect(() => {
        if (showChooseSingleOrPackage) {
            dispatch(setShowCheckoutButton(false));
            dispatch(setShowAllExistingPaymentOptions(false));
        }
    }, [showChooseSingleOrPackage]);

    useEffect(() => {
        const eventsUncoveredByPackage = [];
        cartPreparedForCheckout.forEach((item) => {
            const { howtopay } = item;
            const { passid, amountToCharge } = howtopay;
            if ((passid === 0) & (amountToCharge.total > 0)) {
                eventsUncoveredByPackage.push(item);
            }
        });
        if (
            eventsUncoveredByPackage.length > 0 &&
            !showCCCapture &&
            !showSummaryPaymentScreen &&
            !showChooseSingleOrPackage &&
            !isUsingCreditToCoverClass
        ) {
            dispatch(setShowAllExistingPaymentOptions(true));
        } else if (
            eventsUncoveredByPackage.length === 0 &&
            !showCCCapture &&
            !showSummaryPaymentScreen
        ) {
            dispatch(setShowAllExistingPaymentOptions(false));
        }
    }, [
        cartPreparedForCheckout,
        isUsingCreditToCoverClass,
        showCCCapture,
        showChooseSingleOrPackage,
        showSummaryPaymentScreen,
    ]);
    const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));
    useEffect(() => {
        setTotaltopay(totalAfterCreditApplied);
    }, [totalAfterCreditApplied]);
    useEffect(() => {
        // dispatch(setShowAllExistingPaymentOptions(true));
        const cartitems = [];

        if (window.isTiny) {
            setWidthOfTable(getWidthForTable());
        }

        if (cartPreparedForCheckout.length > 0) {
            setRows(cartPreparedForCheckout);
            // updatePassesAndCreditForUser();
            setHasClasses(true);
        }
        if (cartPreparedForCheckout.length === 0) {
            setHasClasses(false);
            cartitems.length = 0;
            setRows(cartitems);
        }
    }, [cartPreparedForCheckout, dibsId, userid, hasClasses]);
    const sendToLogin = () => {
        window.localStorage.setItem(
            'lastpagevisited',
            `/checkout/${dibsId}${location.search}`,
        );
        setDialogIsOpen(true);
        setTimeout(() => {
            setDialogIsOpen(false);
            dispatch(setIsProcessing(false));
            logout({ clearCartOnLogout: false });
            navigate(`/login/${dibsId}${location.search}`);
        }, 4000);
    };

    const buyTheCart = async () => {
        setIsBuyingSomething(true);
        dispatch(setIsProcessing(true));
        if (!userid || userid === 0) {
            console.log(
                'we do not have a userid for this user - cannot checkout',
            );
            sendToLogin();
            return;
        }

        await checkoutGeneral(
            userid,
            cartPreparedForCheckout,
            creditApplied,
            totalAfterCreditApplied,
            dibsId,
            stripeidForThisStudio,
            cardtocharge,
            paymentOptionsAll,
            stripeid,
        ).then((res) => {
            console.log(
                'TO DO - just checked out - purchasing this class - need to send confirmation email\n',
                res,
            );
            dispatch(setShowCheckoutButton(false));
            // console.log('\n\n\ndibsId = ', dibsId);
            // console.log('userid = ', userid);
            // console.log('cartPreparedForCheckout = ', cartPreparedForCheckout);
            // console.log('creditApplied = ', creditApplied);
            // console.log('totalAfterCreditApplied = ', totalAfterCreditApplied);
            sendConfirmationEmail(
                dibsId,
                userid,
                cartPreparedForCheckout,
                creditApplied,
                totalAfterCreditApplied,
                [],
            );
            const { success, data } = res;
            if (success) {
                setIsBuyingSomething(false);
                dispatch(
                    setCartJustCheckedOut({
                        cartPreparedForCheckout: data,
                        creditApplied,
                        totalAfterCreditApplied,
                        orNewCart: [],
                    }),
                );
                dispatch(clearCart());
                dispatch(clearPurchasingPackage());
                // dispatch(setSuccessMessageReducer('Success!'));
                // dispatch(setSecondsToShow(10));
                // dispatch(setHasSuccessReducer(true));
                // setTimeout(() => {
                //     dispatch(clearSuccessMessageReducer());
                // }, 6000);
                dispatch(setIsProcessing(false));
                setSuccess({
                    msg: `Success! You're booked!`,
                    seconds: 5,
                    success: true,
                });
                navigate(`/checkout/confirm/${dibsId}${location.search}`);
            } else {
                console.log('there was an error checking out');
                dispatch(
                    setErrorMessageReducer(
                        'There was an error checking out. Please refresh your screen and try again.',
                    ),
                );
                dispatch(setSecondsToShow(10));
                dispatch(setHasErrorReducer(true));
                setTimeout(() => {
                    dispatch(clearErrorMessageReducer());
                }, 6000);
                dispatch(setIsProcessing(false));
            }
            setIsBuyingSomething(false);
        });
    };
    const goPayment = () => {
        console.log('set the last page visited here');
        window.localStorage.setItem(
            'lastpagevisited',
            `/checkout/${dibsId}${location.search}`,
        );
        if (totaltopay <= 0) {
            dispatch(setPageSelected('confirm-purchase'));
            navigate(`/confirm/${dibsId}${location.search}`);
        } else {
            dispatch(setPageSelected('payment'));
            navigate(`/payment/${dibsId}${location.search}`);
        }
    };
    const goBackSchedule = () => {
        dispatch(setPageSelected('schedule'));
        navigate(`/schedule/${dibsId}${location.search}`);
    };
    return (
        <Grid container spacing={2} id="checkout-list-container">
            <NotificationAlert
                isOpen={dialogIsOpen}
                displayText={`This transaction requires you to be logged in. Redirecting you to login now....`}
            />
            <Grid item xs={12}>
                <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography variant="subtitle1">Cart Items</Typography>
                    <Typography variant="caption" sx={{ fontSize: '0.875rem' }}>
                        ({rows.length})
                    </Typography>
                </Stack>
            </Grid>
            <Grid item xs={12} border={0}>
                <CartSummaryTable rows={rows} />
            </Grid>
            <Grid item xs={12} id="order-summary">
                <OrderSummary
                    itemsincart={cartPreparedForCheckout}
                    totalPostCredit={totalAfterCreditApplied}
                    creditApplied={creditApplied}
                />
            </Grid>
            {showCCCapture && (
                <Grid item xs={12} id="card-payment-options-cc" sx={{ mb: 1 }}>
                    <CollectCC intentType={intentType} />
                </Grid>
            )}

            {showAllExistingPaymentOptions && (
                <Grid
                    item
                    xs={12}
                    id="card-payment-options-display"
                    sx={{ mb: 1 }}>
                    <DisplayCardOptions payments={payments} />
                </Grid>
            )}
            {/* Showing Payment Summary Screen */}
            {showSummaryPaymentScreen && (
                <SummaryPaymentScreen
                    pl={3}
                    showThis={showConfirmButton}
                    buyAction={buyTheCart}
                />
            )}

            {showCheckoutButton && (
                <CheckoutButton
                    thisid="checkout-list-checkout-button"
                    buttonText="Confirm"
                    clickAction={buyTheCart}
                    isBuying={isBuyingSomething}
                    pl={3}
                    showConfirmButton={showCheckoutButton}
                />
            )}
            {/* {isProcessingCreditCardNow && (
                <ProcessingCC />
            )} */}
            {showChooseSingleOrPackage && (
                <Grid
                    item
                    xs={12}
                    id="card-payment-options-select"
                    sx={{ mb: 6 }}>
                    <SelectPaymentOption
                        itemsincart={cartPreparedForCheckout}
                        totalPostCredit={totalAfterCreditApplied}
                        creditApplied={creditApplied}
                        setShowConfirmButton={setShowConfirmButton}
                        setShowCCForm={setShowCreditCardForm}
                        setHasChosen={setChosenSingleOrPackage}
                    />
                </Grid>
            )}
            <Grid item xs={12} sx={{ mb: 5 }}>
                <Grid
                    direction={matchDownMD ? 'column-reverse' : 'row'}
                    container
                    spacing={3}
                    alignItems={matchDownMD ? '' : 'center'}>
                    <Grid
                        item
                        xs={12}
                        md={12}
                        lg={12}
                        sx={{ mt: 1, mb: 3 }}
                        id="bck-to-schedule-btn">
                        <Button
                            // component={Link}
                            // to={backtoScheduleUrl}
                            onClick={() => goBackSchedule()}
                            variant="text"
                            sx={{
                                color: colortouse,
                            }}
                            startIcon={<KeyboardBackspaceIcon />}>
                            Back to schedule
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

CheckoutList.propTypes = {
    checkout: PropTypes.object,
    updateQuantity: PropTypes.func,
    onNext: PropTypes.func,
};
export default CheckoutList;
