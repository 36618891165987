import * as React from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Grid, Fade } from '@mui/material';
import ApptLayout from 'layouts/appointmentLayout';

import { dispatch, useSelector } from 'store';
import {
    setAppointmentTypes,
    setApptStaff,
    setApptCategories,
} from 'store/slices/appointments';
import {
    setShowingSchedule,
    setShowingAppointments,
    setShowingAccount,
    setFirstLoad,
    setApptCategoryShowing,
} from 'store/slices/menu';

import { setLastLocation } from 'store/slices/thisuser';
import getCategories from 'actions/appointments/getUniqueCategories';

const findCategory = (category) => {
    const serviceCategories = {
        memberships: 'MEMBERSHIPS',
        hair: 'HAIR CARE AND STYLE',
        shaving: 'SHAVING – BEARD CARE',
        lounge: 'LOUNGE',
        style: 'STYLE CONSULTATION',
        all: 'ALL SERVICE CATEGORIES',
    };
    let categoryValue = serviceCategories[category];
    const isCategory = categoryValue ? true : false;
    if (!isCategory) categoryValue = 'ALL SERVICE CATEGORIES';
    return categoryValue;
};

const AppointmentsPageView = () => {
    let { dibsId, category } = useParams();

    const navigate = useNavigate();
    const { firstload } = useSelector((state) => state.menu);
    const { config, studioConfig } = useSelector((state) => state.dibsstudio);
    const {
        timeZone,
        entryPage,
        studioName,
        showAppointments,
        configDoneLoading,
    } = config;
    const [doneLoading, setDoneLoading] = React.useState(false);
    const baseURL = process.env.REACT_APP_BASE_URL;
    const location = useLocation();

    React.useEffect(() => {
        if (dibsId) {
            window.localStorage.setItem('dibsId', dibsId);
        }
    }, [dibsId, firstload]);
    React.useEffect(() => {
        const getcats = async () => {
            const catgs = await getCategories(dibsId);
            console.log('catgs retrieved from db are', catgs);
            const { success, serviceCategories } = catgs;
            if (success) {
                dispatch(setApptCategories(serviceCategories));
            }
            // categories are being populated in redux but not used at this moment bc only pisterzi uses and they have a specific order
        };

        getcats();
    }, [dibsId]);

    React.useEffect(() => {
        const bodytosend = {
            dibsStudioId: dibsId,
        };

        dispatch(setLastLocation(location.pathname));
        const url = `${baseURL}/get-appt-types`;
        const providerUrl = `${baseURL}/appts/get-service-providers`;
        const sendtoschedulepage = () => {
            // navigate to appt page
            dispatch(setShowingSchedule(true));
            dispatch(setShowingAppointments(false));
            dispatch(setFirstLoad(false));
            navigate(`/schedule/${dibsId}${location.search}`);
        };
        const getServiceProviders = async () => {
            await axios.post(providerUrl, { dibsId }).then((response) => {
                dispatch(setApptStaff(response.data));
            });
        };
        if (!showAppointments && configDoneLoading) {
            console.log('sending to schedule page bc not show appointments');
            sendtoschedulepage();
        }
        if (entryPage === 'schedule' && firstload && configDoneLoading)
            sendtoschedulepage();
        if (firstload && configDoneLoading) dispatch(setFirstLoad(false));
        const getApptTypes = async () => {
            await axios
                .post(url, bodytosend)
                .then((response) => {
                    dispatch(setAppointmentTypes(response.data));
                    setDoneLoading(true);
                })
                .catch((error) => {
                    console.log(error);
                });
        };
        getApptTypes();
        getServiceProviders();
        dispatch(setShowingSchedule(false));
        dispatch(setShowingAccount(false));
        dispatch(setShowingAppointments(true));
    }, [
        dibsId,
        baseURL,
        location.pathname,
        entryPage,
        firstload,
        showAppointments,
        navigate,
        configDoneLoading,
        location.search,
    ]);
    React.useEffect(() => {
        let categoryString = 'ALL SERVICE CATEGORIES';
        let isCategory = false;
        if (category) isCategory = true;
        if (isCategory) categoryString = findCategory(category);
        dispatch(setApptCategoryShowing(categoryString));
    }, [category]);
    const loadingDisplay = (
        <Fade in={true} timeout={1000}>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center">
                <Grid item>Loading...</Grid>
            </Grid>
        </Fade>
    );
    const valuetouse = doneLoading ? <ApptLayout /> : loadingDisplay;
    return valuetouse;
};

export default AppointmentsPageView;
