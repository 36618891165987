import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

import { dispatch } from './../index';

const initialState = {
    itemsInCart: [],
    itemsInCartHere: [],
    cartPreparedForCheckout: [],
    cartJustCheckedOut: [],
    creditJustApplied: 0,
    totalAfterCreditJUSTApplied: 0,
    availablePackagesNotBeingUsed: false,
    billing: null,
    cardtocharge: null,
    platformHoldingCardToCharge: 'dibs',
    cartCompletedIsReadyToShow: false,
    creditApplied: 0,
    isUsingCreditToCoverPackage: false,
    isUsingCreditToCoverClass: false,
    discount: 0,
    error: null,
    cardexpy: null,
    cardlastfour: null,
    cardbrand: null,
    editingPaymentMethodInCart: false,
    packEditingFor: 0,
    isProcessingCreditCardNow: false,
    isPurchasingAPackage: false,
    isProcessing: false,
    manualOverridePackageApplication: false,
    needstopay: true,
    packageItemsInCart: [],
    packagesBeingUsed: [],
    potentialRemainingCredit: 0,
    purchasingPackageId: null,
    showCCCapture: false,
    showChooseSingleOrPackage: true,
    showAllExistingPaymentOptions: false,
    showSummaryPaymentScreen: false,
    showCheckoutButton: false,
    step: 0,
    subtotal: 0,
    tax: 0,
    taxrateforclasses: 0.045,
    taxrateforretail: 0,
    total: 0,
    totalAfterCreditApplied: 0,
    discountedpackage: {
        packageid: null,
        packageprice: 0,
        packagetax: 0,
        packagetotalprice: 0,
    },
    payment: {
        method: 'card',
        type: 'free',
    },
};

function roundTo(n, digits) {
    if (digits === undefined) {
        digits = 0;
    }

    var multiplicator = Math.pow(10, digits);
    n = parseFloat((n * multiplicator).toFixed(11));
    return Math.round(n) / multiplicator;
}

const cart = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },
        setIsUsingCreditToCoverPackage(state, action) {
            state.isUsingCreditToCoverPackage = action.payload;
        },
        setIsUsingCreditToCoverClass(state, action) {
            state.isUsingCreditToCoverClass = action.payload;
        },
        setDiscountSuccess(state, action) {
            let difference = 0;
            if (state.checkout.discount > 0) {
                difference = state.checkout.discount;
            }

            state.checkout.discount = action.payload.amount;
            state.checkout.total =
                state.checkout.total + difference - action.payload.amount;
        },
        setPurchasingPackage(state, action) {
            const { packageid, isPurchasing } = action.payload;
            return {
                ...state,
                purchasingPackageId: packageid,
                isPurchasingAPackage: isPurchasing,
            };
        },
        setNewCartPreparedForCheckout(state, action) {
            return {
                ...state,
                cartPreparedForCheckout: action.payload,
            };
        },
        setIsProcessingCreditCardNow(state, action) {
            return {
                ...state,
                isProcessingCreditCardNow: action.payload,
            };
        },
        setEditingPaymentMethod(state, action) {
            const { isEditingMethod, packageId } = action.payload;
            return {
                ...state,
                editingPaymentMethodInCart: isEditingMethod,
                packEditingFor: packageId,
            };
        },
        setShowChooseSingleOrPackage(state, action) {
            return {
                ...state,
                showChooseSingleOrPackage: action.payload,
            };
        },
        setShowAllExistingPaymentOptions(state, action) {
            return {
                ...state,
                showAllExistingPaymentOptions: action.payload,
            };
        },
        setIsProcessing(state, action) {
            return {
                ...state,
                isProcessing: action.payload,
            };
        },
        setShowSummaryPaymentScreen(state, action) {
            return {
                ...state,
                showSummaryPaymentScreen: action.payload,
            };
        },
        setShowCheckoutButton(state, action) {
            return {
                ...state,
                showCheckoutButton: action.payload,
            };
        },
        clearPurchasingPackage(state, action) {
            return {
                ...state,
                purchasingPackageId: null,
                isPurchasingAPackage: false,
            };
        },
        setCartCompletedIsReadyToShow(state, action) {
            return {
                ...state,
                cartCompletedIsReadyToShow: action.payload,
            };
        },
        setCartJustCheckedOut(state, action) {
            const {
                cartPreparedForCheckout,
                creditApplied,
                totalAfterCreditApplied,
                orNewCart,
            } = action.payload;
            let cartPreparedForCheckoutToUse = cartPreparedForCheckout;
            if (orNewCart.length > 0) {
                const newcheckoutlist = [];
                orNewCart.forEach((cartitem) => {
                    const { item, transactionid, credittocharge } = cartitem;
                    const newobject = {
                        ...item,
                        transactionid,
                        credittocharge,
                    };
                    newcheckoutlist.push(newobject);
                });
                cartPreparedForCheckoutToUse = newcheckoutlist;
            }
            return {
                ...state,
                cartJustCheckedOut: cartPreparedForCheckoutToUse,
                creditJustApplied: creditApplied,
                totalAfterCreditJUSTApplied: totalAfterCreditApplied,
            };
        },
        updatePackagesBeingUsed(state, action) {
            const newcart = action.payload;
            const newpacks = [];
            newcart.forEach((item) => {
                const { howtopay } = item;
                const { passid } = howtopay;
                if (passid > 0) {
                    newpacks.push(passid);
                }
            });
            return {
                ...state,
                packagesBeingUsed: newpacks,
            };
        },
        setShowCCCapture(state, action) {
            return {
                ...state,
                showCCCapture: action.payload,
            };
        },
        determineExtraAvailabilityNew(state, action) {
            let isExtraAvailability = false;
            const { passes, newcart } = action.payload;
            const passesBeingUsed = state.packagesBeingUsed;
            let countPassesAvailable = 0;
            let countPassesUsed = passesBeingUsed.length;
            // need to confirm this for private and public need to do
            passes.forEach((pass) => {
                const uses = pass.totalUses;
                const available = pass.usesCount;
                if (uses > available) {
                    const availableFromPackage = uses - available;
                    countPassesAvailable += availableFromPackage;
                }
            });
            countPassesAvailable = countPassesAvailable - countPassesUsed;
            if (countPassesAvailable > 0) isExtraAvailability = true;
            return {
                ...state,
                availablePackagesNotBeingUsed: isExtraAvailability,
            };
        },
        determineExtraAvailability(state, action) {
            let isExtraAvailability = false;
            const { passes, newcart } = action.payload;
            const passesBeingUsed = state.packagesBeingUsed;

            const checkpasses = async () => {
                await newcart.forEach((item) => {
                    const { howtopay, canApplyPass, quantity } = item;
                    const { passid } = howtopay;
                    if (passid === 0 && canApplyPass) {
                        passes.forEach((pass) => {
                            const alreadyUsed = passesBeingUsed.includes(
                                pass.id,
                            );
                            if (!alreadyUsed) {
                                const totalremaininguses =
                                    pass.totalUses - pass.usesCount;
                                let matchesType =
                                    pass.private_pass === item.private
                                        ? true
                                        : false;
                                matchesType =
                                    item.private === null && !pass.private_pass;
                                if (
                                    totalremaininguses >= quantity &&
                                    matchesType
                                )
                                    isExtraAvailability = true;
                            }
                        });
                    }
                });
            };
            checkpasses();
            return {
                ...state,
                availablePackagesNotBeingUsed: isExtraAvailability,
            };
        },
        setManualOverridePackageApplicationAlone(state, action) {
            return {
                ...state,
                manualOverridePackageApplication: action.payload,
            };
        },
        setManualOverridePackageApplication(state, action) {
            const { newPassInfo, eventtochange } = action.payload;
            const indexofCart = state.cartPreparedForCheckout.findIndex((i) => {
                return i.eventid === eventtochange.eventid;
            });
            const { passid } = newPassInfo;
            const { price, quantity } = eventtochange;
            let neweventupdated;
            if (passid === 0) {
                // remove pass from this package
                neweventupdated = {
                    ...eventtochange,
                    howtopay: {
                        method: 'creditcard',
                        amountToCharge: price,
                        quantity,
                        passid: 0,
                        passname: '',
                    },
                };
            } else {
                // there is a new pass to use
                const { studioPackage } = newPassInfo;
                const { packageName } = studioPackage;
                neweventupdated = {
                    ...eventtochange,
                    howtopay: {
                        method: 'with_passid',
                        amountToCharge: 0,
                        quantity,
                        passid: passid,
                        passname: packageName,
                    },
                };
            }
            const newcartforcheckout = [...state.cartPreparedForCheckout];
            newcartforcheckout[indexofCart] = neweventupdated;
            return {
                ...state,
                cartPreparedForCheckout: newcartforcheckout,
                manualOverridePackageApplication: true,
            };
        },
        setPurchasingPackageReducer(state, action) {
            const { packageid, isPurchasing } = action.payload;
            return {
                ...state,
                purchasingPackageId: packageid,
                isPurchasingAPackage: isPurchasing,
            };
        },
        updatePotentialRemainingCredit(state, action) {
            return {
                ...state,
                potentialRemainingCredit: action.payload,
            };
        },
        setTotal(state, action) {
            return {
                ...state,
                total: action.payload,
            };
        },
        setTotalAfterCreditApplied(state, action) {
            return {
                ...state,
                totalAfterCreditApplied: action.payload,
            };
        },
        setCreditApplied(state, action) {
            // const currentCredit = state.creditApplied;
            const newCredit = action.payload;
            return {
                ...state,
                creditApplied: newCredit,
            };
        },
        updateItemsInCartHere(state, action) {
            const dibsIds = action.payload;
            const dibsidparams = dibsIds.dibsIdParams;
            const allcarts = state.itemsInCart;
            const cartthisstudio = [];
            for (let i = 0; i < allcarts.length; i++) {
                if (allcarts[i].dibsStudioId === dibsidparams) {
                    cartthisstudio.push(allcarts[i]);
                }
            }
            return {
                ...state,
                itemsInCartHere: cartthisstudio,
                cartPreparedForCheckout: cartthisstudio,
            };
        },
        setSubTotal(state, action) {
            return {
                ...state,
                subtotal: action.payload,
            };
        },
        setDiscountedPackageInfo(state, action) {
            const { packprice, packtax, packcredit, packtotal } =
                action.payload;
            return {
                ...state,
                discountedpackage: {
                    packageprice: packprice,
                    packagetax: packtax,
                    packagetotalprice: packtotal,
                    packagecredit: packcredit,
                },
            };
        },
        setTotalTax(state, action) {
            const newtax = roundTo(action.payload, 2);
            if (!newtax) return;
            return {
                ...state,
                tax: newtax,
            };
        },
        setNeedsToPay(state, action) {
            return {
                ...state,
                needstopay: action.payload,
            };
        },
        updateQuantityInCart(state, action) {
            const eventinfo = action.payload;
            const { eventid, updatedQuantity } = eventinfo;
            const findIndexAndUpdate = (array) => {
                const indexfound = array.findIndex(
                    (i) => i.eventid === eventid,
                );
                array[indexfound].quantity = updatedQuantity;
            };
            findIndexAndUpdate(state.itemsInCart);
            findIndexAndUpdate(state.itemsInCartHere);
            findIndexAndUpdate(state.cartPreparedForCheckout);
            // const indexOfPackage = state.itemsInCart.findIndex(
            //     (i) => i.eventid === eventid,
            // );
            // state.itemsInCart[indexOfPackage].quantity = updatedQuantity;
        },
        updatePackageQuantityInCart(state, action) {
            const packinfo = action.payload;
            const { id, updatedQuantity } = packinfo;

            const indexOfPackage = state.packageItemsInCart.findIndex(
                (i) => i.packageid === id,
            );
            // const currentPackage = {
            //     ...state.packageItemsInCart[indexOfPackage],
            //     quantity: updatedQuantity,
            // };
            // console.log(
            //     `currentPackage to set is: ${JSON.stringify(currentPackage)}`,
            // );
            state.packageItemsInCart[indexOfPackage].quantity = updatedQuantity;
        },
        setCardToCharge(state, action) {
            return {
                ...state,
                cardtocharge: action.payload,
            };
        },
        setPlatformHoldingCardToCharge(state, action) {
            return {
                ...state,
                platformHoldingCardToCharge: action.payload,
            };
        },
        setExpy(state, action) {
            return {
                ...state,
                cardexpy: action.payload,
            };
        },
        setLastFourCard(state, action) {
            return {
                ...state,
                cardlastfour: action.payload,
            };
        },
        setCardBrand(state, action) {
            return {
                ...state,
                cardbrand: action.payload,
            };
        },
        addPackageToCart(state, action) {
            const { packageinfo, dibsId } = action.payload;
            let taxamount = 0;
            if (packageinfo.id > 0) {
                taxamount = packageinfo.price * state.taxrateforclasses;
            }
            // let needtoaddsubscription = false;
            // if (packageinfo.autopay !== 'NONE') {
            //     needtoaddsubscription = true;
            // }

            const valuetoadd = {
                packageid: packageinfo.id,
                packageName: packageinfo.packageName,
                numClasses: packageinfo.numClasses,
                price: packageinfo.price,
                autopay: packageinfo.autopay,
                quantity: 1,
                dibsStudioId: dibsId,
                autopayIncrement: packageinfo.autopayIncrement,
                unlimited: packageinfo.unlimited,
                private: packageinfo.private,
                onDemandAccess: packageinfo.onDemandAccess,
                validity: packageinfo.validity,
                validIncrement: packageinfo.validIncrement,
                commitmentPeriod: packageinfo.commitmentPeriod,
                purchaseLimit: packageinfo.purchaseLimit,
                itemtax: taxamount,
            };
            const alreadyInCart = state.packageItemsInCart.find(
                (i) => i.packageid === packageinfo.id,
            );

            if (alreadyInCart) {
                alreadyInCart.quantity += 1;
                alreadyInCart.dibsStudioId = dibsId;
            } else {
                state.packageItemsInCart.push(valuetoadd);
            }
        },
        addToCart(state, action) {
            const { event, dibsId, howtopay } = action.payload;
            const item = event;
            let taxamount = 0;
            let pricetouse = 0;
            if (event.id > 0) {
                taxamount = item.price * state.taxrateforclasses;
                taxamount = roundTo(taxamount, 2);
                pricetouse = item.price;
            }
            if (howtopay.passid > 0) {
                state.packagesBeingUsed.push(howtopay.passid);
            }

            const valuetoadd = {
                eventid: item.id,
                eventTime: item.eventTime,
                eventName: item.eventName,
                quantity: 1,
                dibsStudioId: dibsId,
                startDate: item.startDate,
                private: item.private,
                price: pricetouse,
                locationName: item.locationName,
                instructorName: item.instructorName,
                itemtax: taxamount,
                howtopay: howtopay,
                canApplyPass: item.canApplyPass,
            };
            let indexfound = -1;
            if (state.itemsInCart.length > 0) {
                indexfound = state.itemsInCart.findIndex((i) => {
                    return i.eventid === item.id;
                });
            }
            if (indexfound > -1) {
                state.itemsInCart[indexfound].quantity += 1;
            } else {
                state.itemsInCart.push(valuetoadd);
            }
            state.itemsInCartHere.length = 0;
            state.itemsInCart.forEach((e) => {
                if (e.dibsStudioId === dibsId) {
                    state.itemsInCartHere.push(e);
                }
            });
        },
        setItemsInCartWithPasses(state, action) {
            const { res } = action.payload;
            state.cartPreparedForCheckout.length = 0;

            const eventItems = res;
            eventItems.forEach((itemincart) => {
                state.cartPreparedForCheckout.push(itemincart);
            });
        },
        updateCartTotals(state, action) {
            const { subtotal, tax, total } = action.payload;
            state.subtotal = subtotal;
            state.tax = tax;
            state.total = total;
        },
        removeFromCart(state, action) {
            const id = action.payload;
            const itemInCart = state.itemsInCart.find((i) => i.id === id);
            if (itemInCart.quantity > 1) {
                itemInCart.quantity -= 1;
            } else {
                state.itemsInCart = state.itemsInCart.filter(
                    (i) => i.id !== id,
                );
            }
        },
        deleteEventItemCompletelyFromCart(state, action) {
            const eventid = action.payload;
            state.itemsInCart = state.itemsInCart.filter(
                (i) => i.eventid !== eventid,
            );

            state.itemsInCartHere = state.itemsInCartHere.filter(
                (i) => i.eventid !== eventid,
            );
            state.cartPreparedForCheckout =
                state.cartPreparedForCheckout.filter(
                    (i) => i.eventid !== eventid,
                );

            let creditToApply = 0;
            let creditToApplyPrevState = state.creditApplied;
            if (creditToApplyPrevState > 0) {
                state.cartPreparedForCheckout.forEach((item) => {
                    const { howtopay } = item;
                    const { amountToCharge } = howtopay;
                    creditToApply += amountToCharge.total;
                });
                if (creditToApply <= creditToApplyPrevState) {
                    state.creditApplied = creditToApply;
                } else {
                    console.log(
                        'there is a problem with updating the credit to apply after deleting an item from the cart',
                    );
                }
            }
            const removeFromCartPreparedForCheckout = () => {
                const indexfound = state.cartPreparedForCheckout.findIndex(
                    (i) => {
                        return i.eventid === eventid;
                    },
                );
                // need to remove credit applied from cart
                if (indexfound > -1) {
                    const { howtopay } =
                        state.cartPreparedForCheckout[indexfound];
                    const { amountToCharge } = howtopay;
                    if (state.creditApplied > 0) {
                        state.creditApplied =
                            (state.creditApplied - amountToCharge.total) *
                            howtopay.quantity;
                    }
                    state.cartPreparedForCheckout.splice(indexfound, 1);
                }
            };
            removeFromCartPreparedForCheckout();
            // state.cartPreparedForCheckout =
            //     state.cartPreparedForCheckout.filter(
            //         (i) => i.eventid !== eventid,
            //     );
        },
        deletePackageItemCompletelyFromCart(state, action) {
            const pack = action.payload;
            const quantity = pack.quantity;
            state.packageItemsInCart = state.packageItemsInCart.filter(
                (i) => i.packageid !== pack.packageid,
            );
            const pricequantity = pack.price * quantity;
            const newsubtotal = Math.max(state.subtotal - pricequantity, 0);
            const taxquantity = pack.itemtax * quantity;
            // const newtax = state.tax - taxquantity;
            const newtax = Math.max(state.tax - taxquantity, 0);
            const newtotal = newsubtotal + newtax;
            state.subtotal = newsubtotal;
            state.tax = newtax;
            state.total = newtotal;
        },
        clearCart(state, action) {
            const dibsId = action.payload;
            state.itemsInCart = state.itemsInCart.filter(
                (it) => it.dibsStudioId === dibsId,
            );
            state.itemsInCartHere.length = 0;
            state.cartPreparedForCheckout.length = 0;
            state.packageItemsInCart.length = 0;
            state.total = 0;
            state.totalAfterCreditApplied = 0;
            state.subtotal = 0;
            state.tax = 0;
            state.discount = 0;
            state.creditApplied = 0;
            state.manualOverridePackageApplication = false;
            state.isUsingCreditToCoverPackage = false;
        },
        refreshCartItemsWhenChangingStudios(state, action) {
            const { dibsId } = action.payload;

            const allcarts = state.itemsInCart;
            const cartforthisstudio = [];
            for (let i = 0; i < allcarts.length; i++) {
                if (allcarts[i].dibsStudioId === dibsId) {
                    cartforthisstudio.push(allcarts[i]);
                }
            }

            return {
                ...state,
                itemsInCartHere: cartforthisstudio,
                // cartPreparedForCheckout: cartforthisstudio,
            };
        },
    },
});
export default cart.reducer;
export function setDiscount(code, total) {
    return async () => {
        try {
            const response = await axios.post('/api/cart/discount', {
                code,
                total,
            });
            dispatch(cart.actions.setDiscountSuccess(response.data));
        } catch (error) {
            dispatch(cart.actions.hasError(error));
        }
    };
}
export function resetCart() {
    return async () => {
        try {
            const response = await axios.post('/api/cart/reset');
            dispatch(cart.actions.resetCardSuccess(response.data));
        } catch (error) {
            dispatch(cart.actions.hasError(error));
        }
    };
}
export const {
    addToCart,
    removeFromCart,
    setCardToCharge,
    setPlatformHoldingCardToCharge,
    setIsProcessingCreditCardNow,
    setExpy,
    setLastFourCard,
    setTotal,
    setSubTotal,
    setTotalTax,
    clearCart,
    setIsProcessing,
    setShowChooseSingleOrPackage,
    addPackageToCart,
    updatePackagesBeingUsed,
    setDiscountedPackageInfo,
    clearPurchasingPackage,
    setNeedsToPay,
    updateItemsInCartHere,
    updateQuantityInCart,
    updatePackageQuantityInCart,
    setTotalAfterCreditApplied,
    setShowCCCapture,
    setCreditApplied,
    setCardBrand,
    deleteEventItemCompletelyFromCart,
    deletePackageItemCompletelyFromCart,
    setItemsInCartWithPasses,
    refreshCartItemsWhenChangingStudios,
    setPurchasingPackage,
    setPurchasingPackageReducer,
    determineExtraAvailability,
    determineExtraAvailabilityNew,
    setManualOverridePackageApplication,
    setManualOverridePackageApplicationAlone,
    setShowSummaryPaymentScreen,
    updateItemsInCartThisStudio,
    setNewCartPreparedForCheckout,
    updatePotentialRemainingCredit,
    setCartJustCheckedOut,
    setCartCompletedIsReadyToShow,
    setShowAllExistingPaymentOptions,
    setEditingPaymentMethod,
    setShowCheckoutButton,
    setIsUsingCreditToCoverPackage,
    setIsUsingCreditToCoverClass,
} = cart.actions;
